.call-card-container {
    z-index: 100;
    position: fixed;
    top: 6rem;
    right: 1rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 20rem;
    max-width: 90vw;
}

.call-card {
    width: 100%;
    padding: 1rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.call-card-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.call-card-header-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.call-card-header-avatar-image {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
}

.call-card-header-info-name-text {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: center;
    width: 100%;
}

.call-card-header-info-call-type-text {
    text-align: center;
    width: 100%;
}

.call-card-actions-container {
    margin: 2rem 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.call-card-action-button {
    width: 4rem;
    height: 4rem;
    border-radius: 50% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
