.demoHelpers {
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 1000;
}

.demoHelperContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.infoIcon {
    cursor: pointer;
}

.demoButton1 {
    cursor: pointer;
}

.demoButton2 {
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .demoHelpers {
        bottom: 60px;
        right: 18px;
    }
}