:root {
  --base-color: #9b52e1;
  --burnt-sienna: #ED5549;
}

.step-counter {
  margin-top: 2.2rem;
}

.overflow-auto {
  overflow-x: auto;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-auto::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-auto {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.stepper-wrapper {
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 98%;
  min-width: 1040px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media (max-width: 768px) {
  .stepper-item {
    font-size: 12px;
  }
}


.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 23px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 23px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-name {
  position: relative;
  top: -20px;
  font-weight: bold;
  color: var(--base-color);
  font-size: 1rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: -30px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px;
  border: 2px solid #ccc;
  font-weight: 1000;
  color: #ccc;
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
}

.stepper-item .active_step {
  background-color: #fff;
  border: 2px solid var(--base-color);
  color: var(--base-color);
}

.stepper-item .step-counter.active_step {
  box-shadow: 0px 1px 4px rgb(85 105 255 / 25%), 0px 3px 12px 2px rgb(85 105 255 / 35%);
}

.stepper-item.completed .step-counter {
  background-color: var(--base-color);
  border: 2px solid var(--base-color);
  color: #fff;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--base-color);
  width: 100%;
  top: 23px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.stepper-item.completed .step-counter:hover {
  border: 2px solid rgb(124, 65, 180);
  background-color: rgb(124, 65, 180) !important;
}

.step-label-active {
  color: #5569ff;
  font-weight: bold;
}


.emTeya,
.jYfPHm {
  min-height: 500px !important;
  max-width: 700px;
}

.dtwdEO {
  font-size: 12px !important;
}

.price-text {
  text-align: center;
}

.exp-title {
  flex: 0 0 30%;
  margin-right: 1rem;
}

@media (max-width: 600px) {
  .exp-title {
    margin-bottom: 1rem;
  }
}

.exp-emptype {
  flex: 0 0 30%;
  margin-right: 1rem;
}

@media (max-width: 600px) {
  .exp-emptype {
    margin-bottom: 1rem;
  }
}

.exp-start {
  margin-right: 5rem;
}

@media (max-width: 600px) {
  .exp-start {
    margin-bottom: 1rem;
  }
}

.edu-school {
  flex: 0 0 30%;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .edu-school {
    margin-bottom: 1rem;
  }
}

.edu-field {
  flex: 0 0 30%;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .edu-field {
    margin-bottom: 1rem;
  }
}

.edu-start {
  margin-right: 2rem;
}

@media (max-width: 500px) {
  .edu-start {
    margin-bottom: 1rem;
  }
}

.portfolio-text {
  text-align: center;
}

.description {
  word-wrap: break-word;
}

@media (max-width: 767px) {
.main-business-onboarding-container .MuiStepLabel-label  {font-size: 12px;}
}