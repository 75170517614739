.google-auth-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin: 0 10px;
}
.google-auth-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  height: 3rem;
  border-radius: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: white;
  width: 100%;
}
.google-auth-button:hover {
  cursor: pointer;
  background-color: rgba(245, 245, 245, 1);
}
.google-auth-button-text {padding-left: 15px;}

@media screen and (max-width: 767px) {
  .google-auth-button-container {display: block; width: 100%; margin: auto; }
  .google-auth-button {width: 100%; justify-content: center;}

}
