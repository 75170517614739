.linkedin-auth-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin: 0 10px;
}
.linkedin-auth-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 3rem;
    background-color: white;
    height: 3rem;
    width: 100%;
    
}
.linkedin-auth-button:hover {
    cursor: pointer;
    background-color: rgba(245, 245, 245, 1);
}

.linkedin-auth-button-text {padding-left: 15px;}

.social-integrations-logo {
    height: 2rem;
    background-color: white;
}

@media screen and (max-width: 767px) {
.linkedin-auth-button-container {display: block; margin: auto; margin-top: 10px;    width: 100%;}
.linkedin-auth-button {width: 100%; justify-content: center;}
}