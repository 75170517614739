
.li-list {padding-left: 15px; position: relative;}
.li-list::before {content: ""; width: 5px; height: 5px; background: #223354; border-radius: 50%; position: absolute; left: -10px; top: 12px; }
.listProList {padding: 0; padding-top: 10px;}
.listProList li {padding-top: 0;}
.listProList li .li-list::before {top: 10px;}
.listSec6 {padding-bottom: 0px !important;}
.headingFreTooltip {position: relative; top: -10px;}
.browsJObTooltip {position: relative; top: -10px;}
.iconSize svg {font-size: 16px;}
.aaaa .MuiTooltip-popper {background: #fff !important;}
