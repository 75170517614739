.menu-holding-container {
    margin-left: 2%;
}

.header-menu-list-container {
    background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 3rem;
}

.multilist-item-container {
    padding: 0.5rem;
    width: "auto";

    margin-bottom: 0;
    height: "3rem";
}

.disableMenu {cursor: default !important;}