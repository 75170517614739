.meeting-call-holding-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
}

.meeting-call-container {
    width: 65rem;
    max-width: calc(100vw - 10rem);
    max-height: 50rem;
    max-height: calc(100vh - 10rem);
    height: 95%;
    background: white;
    border-radius: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.meeting-call-header {
    padding: 1rem;
    font-size: 1.1rem;
}

.meeting-call-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 95%;
}

.other-person-meeting-call-video-container {
    width: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
    height: 95%;
    text-align: center;
}

.other-person-meeting-call-video {
    max-width: 100%;
    max-height: 100%;
    height: 95%;
}

.connecting-video-text {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 10000;
    color: white;
}

.other-person-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
}

.other-person-avatar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.other-person-avatar-image {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
}

.other-person-name-text {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 0.5rem 0;
    width: 100%;
    position: absolute;
    text-align: left;
    left: 0.5rem;
    bottom: 0.5rem;
}

.self-meeting-call-video-container {
    height: 95%;
    background-color: grey;
    text-align: center;
    width: 50%;
    border-radius: 1rem;
    margin: 5px;
}

.meeting-call-details-container-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem;
}

.meeting-call-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.meeting-call-action-button {
}

.meeting-time-text {
    margin-left: 1rem;
    margin-right: 1rem;
}

.self-meeting-call-video {
    border-radius: 0.5rem;
    height: 95%;
    max-width: 100%;
    max-height: 95%;
}
