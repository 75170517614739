.floater-demo-container {

    display: flex;
    justify-content: center;
    padding: 1rem;

    @media screen and (max-width: 640px) {
        margin: auto;
        padding: 0;
        width: 100%;
        flex-direction: column;
    }
}